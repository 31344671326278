"use client";

import { useTranslate } from "@/components/base/providers/TranslateProv";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { useCallback, useEffect, useState } from "react";
import SearchAndFilter from ".";
import {
  useGetAllCitiesQuery,
  useGetAllFacultiesListDropDownQuery,
  useGetAllRegionsQuery,
  useGetAllSpecializationListDropDownQuery,
  useGetFilterFacultiesbyLocationQuery,
  useGetFilterPlaceQuery,
  useGetFilterspecializationQuery,
  useGetFilterUniversitybyFacultyQuery,
  useGetFilterUniversitybyLocationQuery,
  useGetFilterUniversitybyspecializationQuery,
  useGetUniversityDropDownQuery,
  useGetUniversityTypesQuery,
} from "@/redux/features/api/dropdown/dropdownSlice";
import { Switch } from "@/components/ui/switch";

type Option = {
  value: number | string;
  label: string;
};

interface FormValues {
  search?: string | number;
  universityType?: string | number;
  location?: string | number;
  university?: string | number;
  faculty?: string | number;
  specializations?: string | number;
}

interface MainSearchComponentProps {
  activeTab?: number;
}

const MainSearchComponent: React.FC<MainSearchComponentProps> = ({
  activeTab = 1,
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const [formValues, setFormValues] = useState<FormValues>({
    universityType: "",
    location: "",
    university: "",
    faculty: "",
    specializations: "",
  });
  const [changeLocalStorage, setChangeLocalStorage] = useState(false);
  const [switchSearch, setSwitchSearch] = useState(true);
  const [tabIndex, setTabIndex] = useState(activeTab);

  const { t, locale }: { t: any; locale: "ar" | "en" } = useTranslate("home");

  const { data: universityTypes } = useGetUniversityTypesQuery({
    lang: locale,
  });

  // Switch search => false
  const { data: cities } = useGetAllCitiesQuery({
    countryId: 59,
    lang: locale,
  });
  const { data: regions } = useGetAllRegionsQuery({
    lang: locale,
  });
  const { data: universities } = useGetUniversityDropDownQuery({});
  const { data: faculties } = useGetAllFacultiesListDropDownQuery({});
  const { data: specializations } = useGetAllSpecializationListDropDownQuery(
    {}
  );

  // Switch search => true
  const { data: filterPlace, refetch: refetchFilterPlace } =
    useGetFilterPlaceQuery({
      university_type: formValues.universityType,
      lang: locale,
    });

  const {
    data: filterUniversityByLocation,
    refetch: refetchFilterUniversityByLocation,
  } = useGetFilterUniversitybyLocationQuery({
    location: formValues.location,
    university_type_id: formValues.universityType,
    lang: locale,
  });

  const {
    data: filterFacultiesByLocation,
    refetch: refetchFilterFacultiesByLocation,
  } = useGetFilterFacultiesbyLocationQuery({
    location: formValues.location,
    university_type_id: formValues.universityType,
    lang: locale,
  });

  const {
    data: filterUniversityByFaculty,
    refetch: refetchFilterUniversityByFaculty,
  } = useGetFilterUniversitybyFacultyQuery({
    faculty: formValues.faculty,
    university_type_id: formValues.universityType,
    location: formValues.location,
    lang: locale,
  });

  const { data: filterspecialization, refetch: refetchfilterspecialization } =
    useGetFilterspecializationQuery({
      faculty: formValues.faculty,
      university_type_id: formValues.universityType,
      location: formValues.location,
      lang: locale,
    });

  const {
    data: filteruniversityBySpecialization,
    refetch: refetchfilteruniversityBySpecialization,
  } = useGetFilterUniversitybyspecializationQuery({
    faculty: formValues.faculty,
    university_type_id: formValues.universityType,
    location: formValues.location,
    specialization_id: formValues.specializations,
    lang: locale,
  });

  const mapToOptions = useCallback(
    (data: any): Option[] => {
      return (
        data?.map((item: any) => ({
          value: item.id,
          label: item[`name_${locale}`] || item.name[locale],
        })) || []
      );
    },
    [locale]
  );

  const onSubmit = useCallback(
    (values: FormValues, route: string): void => {
      try {
        const newSearchParams = new URLSearchParams(searchParams.toString());

        Object.entries(values).forEach(([key, value]) => {
          if (value != null && value !== "") {
            newSearchParams.set(key, value.toString().trim());
          } else {
            newSearchParams.delete(key);
          }
        });

        const queryString = newSearchParams.toString();
        const newPath = queryString
          ? `/${locale}/${route}/?${queryString}`
          : `/${route}/`;

        // Remove stored values from localStorage
        localStorage.removeItem("storedValues");

        router.push(newPath);
      } catch (error) {
        console.error("Error navigating to universities page:", error);
      }
    },
    [router, searchParams, locale]
  );

  useEffect(() => {
    switch (true) {
      case pathname.includes("/universities"):
        setTabIndex(1);
        break;
      case pathname.includes("/faculities"):
        setTabIndex(2);
        break;
      case pathname.includes("/specialties"):
        setTabIndex(3);
        break;
    }
  }, [pathname]);

  const getInitialValues = useCallback((): FormValues => {
    return {
      search: searchParams.get("search") || "",
      universityType: searchParams.get("university_type") || "",
      location: searchParams.get("location") || "",
      university: searchParams.get("university") || "",
      faculty: searchParams.get("faculty") || "",
      specializations: searchParams.get("specializations") || "",
    };
  }, [searchParams]);

  const initialValues = getInitialValues();

  useEffect(() => {
    if (switchSearch) {
      const storedValues = localStorage.getItem("storedValues");
      if (storedValues) {
        try {
          const parsedValues = JSON.parse(storedValues);
          setFormValues({
            universityType: parsedValues.funiversity_type || "",
            location: parsedValues.flocation || "",
            university: parsedValues.funiversity || "",
            faculty: parsedValues.ffaculty || "",
            specializations: parsedValues.fspecialization || "",
          });
        } catch (error) {
          console.error(
            "Error parsing stored values from local storage:",
            error
          );
        }
      }
    } else {
      // Remove stored values from localStorage
      localStorage.removeItem("storedValues");

      setFormValues({
        universityType: "",
        location: "",
        university: "",
        faculty: "",
        specializations: "",
      });
    }
  }, [switchSearch, changeLocalStorage]);

  useEffect(() => {
    if (formValues.universityType) {
      refetchFilterPlace();
      console.log("object0");
    }
  }, [formValues.universityType]);

  useEffect(() => {
    if (formValues.location && formValues.universityType) {
      console.log("object1");
      refetchFilterUniversityByLocation();
      refetchFilterFacultiesByLocation();
    }
  }, [formValues.location, formValues.universityType]);

  useEffect(() => {
    if (
      formValues.faculty &&
      formValues.universityType &&
      formValues.location
    ) {
      console.log("object2");

      refetchFilterUniversityByFaculty();
      refetchfilterspecialization();
    }
  }, [formValues.faculty, formValues.universityType, formValues.location]);

  useEffect(() => {
    if (
      formValues.faculty &&
      formValues.universityType &&
      formValues.location &&
      formValues.specializations
    ) {
      console.log("object3");

      refetchfilteruniversityBySpecialization();
    }
  }, [
    formValues.faculty,
    formValues.universityType,
    formValues.location,
    formValues.specializations,
  ]);
  const [searchLocation, setSearchLocation] = useState("1");

  // remove local storage
  useEffect(() => {
    localStorage.removeItem("storedValues");
    setFormValues({
      universityType: "",
      location: "",
      university: "",
      faculty: "",
      specializations: "",
    });
  }, [tabIndex]);

  const searchPlaceholderText = {
    ar: {
      universities: "ابحث عن جامعة",
      faculties: "ابحث عن كلية",
      specializations: "ابحث عن تخصص",
    },
    en: {
      universities: "Search for university",
      faculties: "Search for faculty",
      specializations: "Search for specialization",
    },
  };

  return (
    <div
      className={`container relative w-full lg:max-w-5xl mt-12 bg-white shadow-2xl p-5 ${
        locale == "ar"
          ? " rounded-bl-[14px] rounded-br-[14px]"
          : " rounded-bl-[14px] rounded-br-[14px]"
      }`}
    >
      <div className="flex items-center justify-between w-full absolute -top-10 start-0">
        <div className="">
          {["universities", "colleges", "specializations"].map((tab, index) => (
            <button
              key={tab}
              className={`text-[12px] md:mb-2  mb-0  md:text-xl font-normal font-['Somar Sans'] capitalize leading-[30px] py-1 px-2  md:py-[6px] md:px-[20px] ${
                tabIndex === index + 1
                  ? "text-white bg-[#465ff1] "
                  : "text-darkText bg-white hover:bg-gray-100"
              } ${
                index === 0
                  ? locale === "ar"
                    ? "rounded-tr-[14px]"
                    : "rounded-tl-[14px]"
                  : ""
              } 
                  ${
                    index === 2
                      ? locale === "ar"
                        ? "rounded-tl-[14px]"
                        : "rounded-tr-[14px]"
                      : ""
                  }`}
              onClick={() => setTabIndex(index + 1)}
            >
              {t[tab]}
            </button>
          ))}
        </div>
        <div className=" text-[12px] md:-mt-4 md:text-[16px] bg-white hover:bg-gray-100 flex items-center justify-center gap-1 md:gap-2 px-2 py-3 md:p-3 rounded-t-[14px]">
          <Switch
            small={true}
            checked={switchSearch}
            onCheckedChange={() => setSwitchSearch(!switchSearch)}
          />
          <p>{locale === "ar" ? "الفلتره التراكميه" : "Composite Filter"}</p>
        </div>
      </div>
      {tabIndex === 1 && (
        <SearchAndFilter
          setChangeLocalStorage={setChangeLocalStorage}
          locale={locale}
          switchSearch={switchSearch}
          defaultValues={{
            search: initialValues.search,
            university_type: initialValues.universityType,
            location: initialValues.location,
            university: initialValues.university,
          }}
          searchPlaceholder={searchPlaceholderText[locale]["universities"]}
          filters={[
            {
              label: t.universityType,
              placeholder: t.selectUniversityType,
              options: mapToOptions(universityTypes?.data),
              name: "university_type",
            },
            {
              label: t.location,
              placeholder: t.selectLocation,
              options:
                searchLocation === "1"
                  ? switchSearch
                    ? mapToOptions(filterPlace?.data?.cities)
                    : mapToOptions(cities?.data)
                  : switchSearch
                  ? mapToOptions(filterPlace?.data?.regions)
                  : mapToOptions(regions?.data),
              name: "location",
              searchLocation: searchLocation,
              setSearchLocation: setSearchLocation,
            },
            {
              label: t.university,
              placeholder: t.selectUniversity,
              options: switchSearch
                ? mapToOptions(filterUniversityByLocation?.data)
                : mapToOptions(universities?.data),

              name: "university",
            },
          ]}
          onSubmit={(values) => onSubmit(values, "universities")}
          t={t}
        />
      )}
      {tabIndex === 2 && (
        <SearchAndFilter
          setChangeLocalStorage={setChangeLocalStorage}
          switchSearch={switchSearch}
          locale={locale}
          defaultValues={{
            search: initialValues.search,
            university_type: initialValues.universityType,
            location: initialValues.location,
            university: initialValues.university,
            faculty: initialValues.faculty,
          }}
          searchPlaceholder={searchPlaceholderText[locale]["faculties"]}
          filters={[
            {
              label: t.universityType,
              placeholder: t.selectUniversityType,
              options: mapToOptions(universityTypes?.data),
              name: "university_type",
            },
            {
              label: t.location,
              placeholder: t.selectLocation,
              options:
                searchLocation === "1"
                  ? switchSearch
                    ? mapToOptions(filterPlace?.data?.cities)
                    : mapToOptions(cities?.data)
                  : switchSearch
                  ? mapToOptions(filterPlace?.data?.regions)
                  : mapToOptions(regions?.data),
              name: "location",
              searchLocation: searchLocation,
              setSearchLocation: setSearchLocation,
            },
            {
              label: t.faculty,
              placeholder: t.selectFaculty,
              options: switchSearch
                ? mapToOptions(filterFacultiesByLocation?.data)
                : mapToOptions(faculties?.data),
              name: "faculty",
            },
            {
              label: t.university,
              placeholder: t.selectUniversity,
              options: switchSearch
                ? mapToOptions(filterUniversityByFaculty?.data)
                : mapToOptions(universities?.data),
              name: "university",
            },
          ]}
          onSubmit={(values) => onSubmit(values, "faculities")}
          t={t}
        />
      )}
      {tabIndex === 3 && (
        <SearchAndFilter
          setChangeLocalStorage={setChangeLocalStorage}
          switchSearch={switchSearch}
          locale={locale}
          defaultValues={{
            search: initialValues.search,
            university_type: initialValues.universityType,
            location: initialValues.location,
            university: initialValues.university,
            specialization: initialValues.specializations,
          }}
          searchPlaceholder={searchPlaceholderText[locale]["specializations"]}
          filters={[
            {
              label: t.universityType,
              placeholder: t.selectUniversityType,
              options: mapToOptions(universityTypes?.data),
              name: "university_type",
            },
            {
              label: t.location,
              placeholder: t.selectLocation,
              options:
                searchLocation === "1"
                  ? switchSearch
                    ? mapToOptions(filterPlace?.data?.cities)
                    : mapToOptions(cities?.data)
                  : switchSearch
                  ? mapToOptions(filterPlace?.data?.regions)
                  : mapToOptions(regions?.data),
              name: "location",
              searchLocation: searchLocation,
              setSearchLocation: setSearchLocation,
            },
            {
              label: t.faculty,
              placeholder: t.selectFaculty,
              options: switchSearch
                ? mapToOptions(filterFacultiesByLocation?.data)
                : mapToOptions(faculties?.data),
              name: "faculty",
            },
            {
              label: t.specialization,
              placeholder: t.selectSpecialization,
              options: switchSearch
                ? mapToOptions(filterspecialization?.data)
                : mapToOptions(specializations?.data),
              name: "specialization",
            },
            {
              label: t.university,
              placeholder: t.selectUniversity,
              options: switchSearch
                ? mapToOptions(filteruniversityBySpecialization?.data)
                : mapToOptions(universities?.data),
              name: "university",
            },
          ]}
          onSubmit={(values) => onSubmit(values, "specialties")}
          t={t}
        />
      )}
    </div>
  );
};

export default MainSearchComponent;
