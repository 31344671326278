import { apiDropDown } from "@/redux/app/api/apiSlice";

export const dropdownSlice = apiDropDown.injectEndpoints({
  endpoints: (builder) => ({
    getUniversityTypes: builder.query({
      query: ({ params, lang }) => ({
        url: `/api/drop-down/university-types`,
        params: { ...params },
        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),
    getAllCountries: builder.query({
      query: ({ params, lang }) => ({
        url: `/api/drop-down/country`,
        params: { ...params },
        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),
    getAllCities: builder.query({
      query: ({ countryId, lang }) => ({
        url: `/api/drop-down/city?country_id=${countryId}`,
        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),
    getAllRegions: builder.query({
      query: ({ params, lang }) => ({
        url: `/api/drop-down/region`,
        params: { ...params },
        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),
    GetUniversityDropDown: builder.query({
      query: () => ({
        url: `/api/drop-down/university`,
        method: "GET",
      }),
      providesTags: ["DropDown"],
    }),
    getAllFacultiesDropDown: builder.query({
      query: () => ({
        url: `/api/drop-down/faculty`,
        method: "GET",
      }),
      providesTags: ["DropDown"],
    }),
    getAllFacultiesListDropDown: builder.query({
      query: () => ({
        url: `/api/drop-down/general-faculty`,
        method: "GET",
      }),
      providesTags: ["DropDown"],
    }),
    getAllSpecializationListDropDown: builder.query({
      query: () => ({
        url: `/api/drop-down/general-specialization`,
        method: "GET",
      }),
      providesTags: ["DropDown"],
    }),

    // --------------- Filter -------------------
    getFilterPlace: builder.query({
      query: ({ university_type = "all", lang }) => ({
        url: `/api/filter/cumulative-search/get-places?university_type_id=${university_type}`,
        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),
    getFilterUniversitybyLocation: builder.query({
      query: ({ location = "all", university_type_id = "all", lang }) => ({
        url: `/api/filter/cumulative-search/get-university?location=${location}&university_type_id=${university_type_id}`,
        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),

    getFilterFacultiesbyLocation: builder.query({
      query: ({ location = "all", university_type_id = "all", lang }) => ({
        url: `/api/filter/cumulative-search/get-faculty-with-location?location=${location}&university_type_id=${university_type_id}`,

        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),
    getFilterUniversitybyFaculty: builder.query({
      query: ({
        faculty = "all",
        university_type_id = "all",
        location = "all",
        lang,
      }) => ({
        url: `/api/filter/cumulative-search/get-university-with-faculty?general_faculty=${faculty}&university_type_id=${university_type_id}&location=${location}`,
        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),

    // specializatio
    getFilterspecialization: builder.query({
      query: ({
        faculty = "all",
        university_type_id = "all",
        location = "all",
        lang,
      }) => ({
        url: `/api/filter/cumulative-search/get-specialization?general_faculty=${faculty}&university_type_id=${university_type_id}&location=${location}`,
        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),

    getFilterUniversitybyspecialization: builder.query({
      query: ({
        faculty = "all",
        university_type_id = "all",
        location = "all",
        specialization_id = "all",
        lang,
      }) => ({
        url: `/api/filter/cumulative-search/get-universities-in-specialization?general_faculty=${faculty}&university_type_id=${university_type_id}&location=${location}&specialization=${specialization_id}`,
        headers: {
          "Accept-Language": lang,
        },
      }),
      providesTags: ["DropDown"],
    }),
  }),
});

export const {
  useGetUniversityTypesQuery,
  useLazyGetUniversityTypesQuery,
  useGetAllCountriesQuery,
  useLazyGetAllCountriesQuery,
  useGetAllCitiesQuery,
  useLazyGetAllCitiesQuery,
  useGetAllRegionsQuery,
  useLazyGetAllRegionsQuery,
  useGetAllFacultiesDropDownQuery,
  useGetAllFacultiesListDropDownQuery,
  useGetUniversityDropDownQuery,
  useGetAllSpecializationListDropDownQuery,
  useGetFilterPlaceQuery,
  useGetFilterUniversitybyLocationQuery,
  useGetFilterFacultiesbyLocationQuery,
  useGetFilterUniversitybyFacultyQuery,
  useGetFilterspecializationQuery,
  useGetFilterUniversitybyspecializationQuery,
} = dropdownSlice;
