import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cn } from "@/lib/utils";

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  label?: string;
  small?: boolean;
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, small, checked, onCheckedChange, label, ...props }, ref) => (
  <div className="flex items-center gap-2 ">
    <SwitchPrimitives.Root
      style={{ direction: "rtl" }}
      className={cn(
        `peer inline-flex ${
          small ? "md:h-5 h-[18px] w-9 md:w-10" : " h-6 w-11"
        } shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primaryColor data-[state=unchecked]:bg-input`,
        className
      )}
      checked={checked}
      onCheckedChange={onCheckedChange}
      ref={ref}
      {...props}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          `pointer-events-none block ${
            small ? "h-3 w-3 md:w-4 md:h-4" : "h-5 w-5"
          } rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:-translate-x-5 data-[state=unchecked]:translate-x-0`
        )}
      />
    </SwitchPrimitives.Root>
    {label && <span className="text-[#6C7278] text-sm">{label}</span>}
  </div>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
